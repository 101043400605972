import {
  celularRule,
  cnpjRule,
  cpfRule,
  dateRule,
  emailRule,
  fullNameRule,
  requiredRule,
  slugRule,
  validateAlphaNumeric,
} from "./validatons";
import i18n from "@/i18n/wrappers/i18n";

const t = i18n.global.t;

export const partnerTypes = ["pf", "pj"].map((type) => ({
  value: type,
  label: t(`signup.partner_types.${type}`),
}));

export const formFields = [
  {
    step: 1,
    fields: [
      {
        name: "nome",
        value: "",
        type: "text",
        label: t("signup.fields.complete_name"),
        mask: undefined,
        caption: undefined,
        placeholder: undefined,
        rules: [
          (val) => requiredRule(val, t("signup.fields.complete_name")),
          (val) => fullNameRule(val),
        ],
      },
      {
        name: "email",
        value: "",
        type: "email",
        label: t("signup.fields.email"),
        mask: undefined,
        placeholder: "nome@email.com",
        caption: t("signup.fields.captions.email"),
        rules: [
          (val) => requiredRule(val, t("signup.fields.email")),
          (val) => emailRule(val),
        ],
      },
      {
        name: "celular",
        value: "",
        type: "text",
        label: t("signup.fields.celular"),
        mask: "(##) #####-####",
        placeholder: "(XX) XXXXX-XXXX",
        caption: undefined,
        rules: [
          (val) => requiredRule(val, t("signup.fields.celular")),
          (val) => celularRule(val),
        ],
      },
    ],
  },
  {
    step: 2,
    fields: [
      {
        index: 0,
        group: "pf",
        name: "pf_cpf",
        value: "",
        type: "text",
        label: t("signup.fields.cpf"),
        mask: "###.###.###-##",
        caption: undefined,
        placeholder: "XXX.XXX.XXX-XX",
        rules: [
          (val) => requiredRule(val, t("signup.fields.cpf")),
          (val) => cpfRule(val),
        ],
      },
      {
        index: 1,
        group: "pf",
        name: "pf_nascimento",
        value: "",
        type: "date",
        label: t("signup.fields.birth_date"),
        mask: undefined,
        placeholder: undefined,
        caption: undefined,
        rules: [
          (val) => requiredRule(val, t("signup.fields.birth_date")),
          (val) => dateRule(val),
        ],
      },
      {
        index: 2,
        group: "pj",
        name: "pj_cnpj",
        value: "",
        type: "text",
        label: t("signup.fields.cnpj"),
        mask: "##.###.###/####-##",
        placeholder: "XX.XXX.XXX/XXXX-XX",
        caption: undefined,
        rules: [
          (val) => requiredRule(val, t("signup.fields.cnpj")),
          (val) => cnpjRule(val),
        ],
      },
      {
        index: 3,
        group: "pj",
        name: "pj_pf_cpf",
        value: "",
        type: "text",
        label: t("signup.fields.document_cpf_contact"),
        mask: "###.###.###-##",
        caption: undefined,
        placeholder: "XXX.XXX.XXX-XX",
        rules: [
          (val) => requiredRule(val, t("signup.fields.document_cpf_contact")),
          (val) => cpfRule(val),
        ],
      },
      {
        index: 4,
        group: "pj",
        name: "pj_fantasia",
        value: "",
        type: "text",
        label: t("signup.fields.fantasy_name"),
        mask: undefined,
        placeholder: undefined,
        caption: undefined,
        rules: [(val) => requiredRule(val, t("signup.fields.fantasy_name"))],
      },
    ],
  },
  {
    step: 3,
    fields: [
      {
        name: "slug",
        value: "",
        type: "text",
        label: t("signup.fields.slug"),
        mask: undefined,
        caption: t("signup.fields.captions.slug"),
        placeholder: "Ex: achadinhodamaria",
        rules: [
          (val) => requiredRule(val, "identificador"),
          (val) => slugRule(val),
          (val) => validateAlphaNumeric(val)
        ],
      },
      {
        name: "instagram",
        value: "",
        type: "text",
        label: t("signup.fields.social_networks.instagram"),
        mask: undefined,
        placeholder: "@",
        caption: undefined,
        rules: [
          (val) => requiredRule(val, "Instagram")
        ],
      },
    ],
  },
];

export const stepActions = [
  {
    step: 1,
    actions: [
      {
        label: "Próximo",
        icon: "arrow_forward",
      },
    ],
  },
  {
    step: 2,
    actions: [
      {
        label: "Voltar",
        icon: "arrow_back",
      },
      {
        label: "Próximo",
        icon: "arrow_forward",
      },
    ],
  },
  {
    step: 3,
    actions: [
      {
        label: "Voltar",
        icon: "arrow_back",
      },
      {
        label: "Próximo",
        icon: "arrow_forward",
      },
    ],
  },
];

export const getStepActions = (step) => {
  return stepActions.find((action) => action.step === step);
};

export const getFormFieldsByStep = (step) => {
  return formFields.find((field) => field.step === step);
};

export function formatData(data) {
  const formattedData = {};

  data.forEach((item) => {
    formattedData[item.name] = item.value;
  });

  return formattedData;
}
